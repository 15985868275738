import HubspotForm from 'components/Form/HubspotForm';
import useAltForms from 'components/Form/utils/useAltForms';

import type { FormProps } from 'components/Form/HubspotForm';
import type { FC } from 'react';

export interface ComponentFormProps {
  useAlternateForms?: boolean | null | undefined;
}

const ComponentForm: FC<ComponentFormProps & FormProps> = ({ useAlternateForms, ...formProps }) => {
  const alternateForms = useAltForms();

  return (
    <>
      <HubspotForm {...formProps} className="primary-form" />
      {useAlternateForms &&
        alternateForms.map((form, index) => (
          <HubspotForm {...form} className={`alt-form alt-form-${index + 1}`} key={index} />
        ))}
    </>
  );
};

export default ComponentForm;
