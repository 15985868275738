import { twMerge } from 'tailwind-merge';

import type { ImageDataLike } from 'gatsby-plugin-image';
import type { FC } from 'react';

export type StaticImage = {
  file: {
    url: string;
  };
};

export type Url = string | null | undefined;

export type ImageData = ImageDataLike | StaticImage;

const isStaticImage = (image: ImageData): image is StaticImage =>
  !!(image as StaticImage)?.file?.url || !!(image as StaticImage)?.thumbnails?.src;

const imageResolver = (image: ImageData) => ({
  src: isStaticImage(image) ? image?.thumbnails?.src || image?.file?.url : undefined,
});

export interface ImageProps {
  className?: string;
  image: ImageData;
  alt: string;
  loading?: 'eager' | 'lazy' | null;
}

const Image: FC<ImageProps> = ({ className, image, alt, loading, ...props }) => {
  const mediaAsset = image?.[0];

  if (!image) {
    return null;
  }

  const mobileImage = mediaAsset?.bynderImageMobile?.[0];
  const desktopImage = mediaAsset?.bynderImageDesktop?.[0] || mediaAsset?.image || image || mobileImage;

  const imgElement = (styles: string = '') => (
    <img
      {...imageResolver(desktopImage)}
      title={alt}
      alt={alt}
      loading={loading || 'lazy'}
      className={twMerge(styles, className)}
      {...props}
    />
  );

  return mobileImage ? (
    <picture>
      <source srcSet={imageResolver(desktopImage)?.src} media="(min-width: 641px)" />
      <source srcSet={imageResolver(mobileImage)?.src} media="(min-width: 0px)" />
      {imgElement('!h-auto')}
    </picture>
  ) : (
    imgElement()
  );
};

export default Image;
